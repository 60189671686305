import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useWindowFocused } from '../hooks/useWindowFocused';
import { NotificationResponse } from '../models/Notification';
import NotificationService from '../services/NotificationService';
import { FCWithChildren } from '../types/FCWithChildren';
import { currentClientAtom, myClientsAtom } from '../recoil/atoms/Clients';
import { usePbkAuth } from './PbkAuthContext';
import BaseService from '../services/BaseService';

type ContextType = {
  latestUnreadNotifs: NotificationResponse[];
  totalCount: number;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  isLoadingNotifs: boolean;
  showNotifications: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = createContext<ContextType>(null!);

export const useLatestNotificationsProvider = () => useContext(Context);

export const LatestNotificationsProvider: FCWithChildren = (props) => {
  const { children } = props;
  const [isLoadingNotifs, setIsLoadingNotifs] = useState(true);
  const [latestUnreadNotifs, setLatestUnreadNotifs] = useState<NotificationResponse[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const currentClient = useRecoilValue(currentClientAtom);
  const myClients = useRecoilValue(myClientsAtom);
  const { isAuthenticated } = usePbkAuth();

  const showNotifications = useMemo(() => {
    const hasMultipleTenants = [...new Set(myClients.map((x) => x.tenantId))].length > 1;
    return !!currentClient || (!currentClient && !hasMultipleTenants);
  }, [currentClient, myClients]);

  const isWindowFocused = useWindowFocused();

  const loadLatestUnread = useCallback(() => {
    if (showNotifications && isWindowFocused && !!isAuthenticated && !!BaseService.httpClient.defaults.headers['x-client-id']) {
      setIsLoadingNotifs(true);
      NotificationService.getNotifications({
        isRead: false,
        pageNumber: 1,
        pageSize: 25,
      }).then((res) => {
        setLatestUnreadNotifs(res.data);
        setTotalCount(res.totalCount ?? 0);
        setIsLoadingNotifs(false);
      });
    }
  }, [isAuthenticated, isWindowFocused, showNotifications]);

  useEffect(
    function refreshLatestUnread() {
      if (!showNotifications) {
        setTotalCount(0);
      }

      loadLatestUnread();
      const interval = setInterval(() => {
        loadLatestUnread();
      }, 60000);

      return () => clearInterval(interval);
    },
    [currentClient?.id, loadLatestUnread, showNotifications],
  );

  const markAsRead = useCallback((id: string) => {
    NotificationService.updateNotification({ ids: [id], markAsRead: true });
    setLatestUnreadNotifs((prev) => prev.filter((x) => x.id !== id));
    setTotalCount((prev) => prev - 1);
  }, []);

  const markAllAsRead = useCallback(() => {
    NotificationService.markAllAsRead();
    setLatestUnreadNotifs([]);
    setTotalCount(0);
  }, []);

  const values = useMemo<ContextType>(
    () => ({
      latestUnreadNotifs,
      totalCount,
      markAsRead,
      markAllAsRead,
      isLoadingNotifs,
      showNotifications,
    }),
    [showNotifications, isLoadingNotifs, latestUnreadNotifs, markAllAsRead, markAsRead, totalCount],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
