import { createContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../types/FCWithChildren';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DebugHotkeyContext = createContext<boolean>(false);

export const DebugHotkeyProvider: FCWithChildren = (props) => {
  const { children } = props;

  const [debug, setDebug] = useState(false);

  useEffect(() => {
    window.allowDebug = !!import.meta.hot;
  }, []);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (window.allowDebug && e.ctrlKey && e.shiftKey && e.key === 'D') {
        e.preventDefault();
        e.stopPropagation();
        setDebug((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handler);

    return () => window.removeEventListener('keydown', handler);
  }, []);

  return <DebugHotkeyContext.Provider value={debug}>{children}</DebugHotkeyContext.Provider>;
};
